import React from 'react';
import styled from 'styled-components';

const StyledScroller = styled.div`
  white-space: nowrap;
  max-width: 100vw;
  padding-top: 2.5rem;
  padding-bottom: 6rem;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
`;

const Scroller: React.FC = ({ children }) => <StyledScroller>{children}</StyledScroller>;

export default Scroller;
