import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-auto-flow: column;
  grid-template-columns: 1.5rem repeat(4, 1fr) 1.5rem;

  @media screen and (min-width: 768px) {
    grid-gap: 3vw;
  }

  &:before,
  &:after {
    content: '';
  }
`;

const Content: React.FC = ({ children }) => <StyledContent>{children}</StyledContent>;

export default Content;
