import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import getImageData from '$utils/getImageData';
import Button from '$components/Button';
import { ProductCardProps } from './types';
import Figure from './Figure';
import Content from './Content';
import Markdown from '$components/Markdown';

const StyledDiv = styled.div`
  background-color: var(--color-white);
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: var(--soft-elevation);
  width: 18.125em;
  display: grid;
  grid-template-rows: max-content 1fr;
  transition: transform 0.375s cubic-bezier(0.12, 0.48, 0.42, 0.96);

  @media screen and (min-width: 640px) {
    &:hover {
      transform: translateY(-0.25rem);

      img {
        transform: scale(1.02);
      }
    }
  }

  .hover &,
  &:hover,
  &:focus {
    svg {
      transform: translateX(0.25em);
    }
  }
`;

const Title = styled.h3`
  color: var(--color-slate);
  line-height: 2rem;
  margin: 0;
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const ProductCard: React.FC<ProductCardProps> = ({ url, image, heading, content, linkTitle }) => (
  <StyledDiv>
    <AniLink to={url} bg="var(--color-citrus5)" cover direction="left">
      {image && (
        <Figure>
          <StyledImage image={getImageData(image.imageFile)} alt={image.alternativeText} />
        </Figure>
      )}
      <Content>
        <Title>{heading}</Title>
        <Markdown content={content} />
        <Button fake theme="ghost" size="small" showArrow>
          {linkTitle}
        </Button>
      </Content>
    </AniLink>
  </StyledDiv>
);

export default ProductCard;
