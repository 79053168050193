import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.h2`
  color: var(--color-slate);
  display: grid;
  grid-gap: 1.5rem;
  grid-auto-flow: column;
  text-align: center;
`;

const Title: React.FC = ({ children }) => <StyledTitle>{children}</StyledTitle>;

export default Title;
