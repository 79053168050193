import React from 'react';
import styled from 'styled-components';
import ProductCard from '$components/ProductCard';
import dynamicMargin from '$utils/dynamicMargin';
import FadeInSection from '$components/FadeInSection';
import Title from './Title';
import Scroller from './Scroller';
import Content from './Content';
import { BlockOfferLineupProps } from './types';

const Container = styled.section`
  display: grid;
  grid-auto-columns: max-content;
  margin: 0 auto;
  justify-content: center;
  ${dynamicMargin};
`;

const BlockOfferLineup: React.FC<BlockOfferLineupProps> = ({ heading, offers }) => (
  <FadeInSection>
    <Container>
      <Title>{heading}</Title>
      <Scroller>
        <Content>
          {offers.map((offer) => (
            <ProductCard
              key={offer.Heading}
              url={offer.URL}
              image={offer.Image}
              heading={offer.Heading}
              content={offer.Content}
              linkTitle={offer.LinkTitle}
            />
          ))}
        </Content>
      </Scroller>
    </Container>
  </FadeInSection>
);

export default BlockOfferLineup;
