import React from 'react';
import styled from 'styled-components';

const StyledFigure = styled.figure`
  margin: 0;
  width: 100%;
  height: 14.5em;
  grid-row: 1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    img {
      transition: transform 0.5s cubic-bezier(0.12, 0.48, 0.42, 0.96);
      object-fit: cover;
    }
  }
`;

const Figure: React.FC = ({ children }) => <StyledFigure>{children}</StyledFigure>;

export default Figure;
