import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  display: grid;
  grid-template-rows: 2rem 1fr 1.25rem;
  width: 100%;
  padding: 1.5em 2rem 2rem 2rem;
  white-space: normal;
  color: var(--color-body-text);
  grid-row: 2;

  p {
    font-size: 0.875em;
    margin-top: 0.75rem;
    line-height: 1.5rem;
  }
`;

const Content: React.FC = ({ children }) => <StyledContent>{children}</StyledContent>;

export default Content;
